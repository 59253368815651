import { motion } from "framer-motion";
import React from 'react';
import Footer from '../components/footer';
import Navbar from '../components/navbar';
import TopBar from '../components/topbar';
import Background from '../assets/images/banner/back3.jpg';

const contact = () => {
	return (
		<>
			<TopBar/>
			<Navbar />
			<section className="hero-wrap hero-wrap-2" style={{ backgroundImage: `url(${Background})` }} data-stellar-background-ratio="0.5">
				<div className="overlay"></div>
				<div className="container">
					<div className="row no-gutters slider-text align-items-end justify-content-start">
						<div className="col-md-9  pb-5">
							<p className="breadcrumbs"><span className="mr-2"><a href="index.html">Home <i className="fa fa-chevron-right"></i></a></span> <span>Contact us <i className="fa fa-chevron-right"></i></span></p>
							<motion.div
								initial={{
									opacity: 0,
									x: -300
								}}
								whileInView={{
									opacity: 1,
									x: 0,
									transition: {
										duration: 1
									}
								}}

							>
								<h1 className="mb-3 bread">Contact us</h1>
							</motion.div>
						</div>
					</div>
				</div>
			</section>

			<section className="ftco-section contact-section ftco-no-pb" id="contact-section">
				<div className="container">
					<div className="row justify-content-center mb-5 pb-3">
						<div className="col-md-7 heading-section text-center ">
							<motion.div
								initial={{
									opacity: 0,
									x: 600
								}}
								whileInView={{
									opacity: 1,
									x: 0,
									transition: {
										duration: 2
									}
								}}

							>
								<span className="subheading">Contact us</span>
							</motion.div>
							<h2 className="mb-2">Message us for more details</h2>
							<motion.div
								initial={{
									opacity: 0,
									y: 300
								}}
								whileInView={{
									opacity: 1,
									y: 0,
									transition: {
										duration: 1
									}
								}}

							>
								<p>Explore the world of international trade with New Kart Business Private Ltd.. Contact us today to discuss your import and export requirements, and let us be your partner in navigating the path to success.</p>
							</motion.div>
						</div>
					</div>

					<div className="row block-9">
						<div className="col-md-8">
							<form action="#" className="p-4 p-md-5 contact-form">
								<div className="row">
									<div className="col-md-6">
										<div className="form-group">
											<input type="text" className="form-control" placeholder="Your Name" />
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-group">
											<input type="text" className="form-control" placeholder="Your Email" />
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group">
											<input type="text" className="form-control" placeholder="Subject" />
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group">
											<textarea name="" id="" cols="30" rows="7" className="form-control" placeholder="Message"></textarea>
										</div>
									</div>
									<motion.div
										initial={{
											opacity: 0,
											y: 300
										}}
										whileInView={{
											opacity: 1,
											y: 0,
											transition: {
												duration: 1
											}
										}}

									><div className="col-md-12">
											<div className="form-group">
												<input type="submit" value="Send Message" className="btn btn-primary py-3 px-5" />
											</div>
										</div></motion.div>

								</div>
							</form>

						</div>

						<div className="col-md-4 d-flex pl-md-5 contactdata">
							<div className="row">
								<div className="dbox w-100 d-flex ">
									<div className="icon d-flex align-items-center justify-content-center">
										<span className="fa fa-map-marker"></span>
									</div>
									<div className="text">
										<motion.div
											initial={{
												opacity: 0,
												x: 300
											}}
											whileInView={{
												opacity: 1,
												x: 0,
												transition: {
													duration: 2
												}
											}}

										><p style={{color:"#fc5e28"}}><span>Address:</span> 211, RAMLILA GANJ, BALDEOJI MANDIR KE PASS, Sadar Bazar, Jhansi, Jhansi, Uttar Pradesh, 284001</p></motion.div>

									</div>
								</div>
								<div className="dbox w-100 d-flex ">
									<div className="icon d-flex align-items-center justify-content-center">
										<span className="fa fa-globe"></span>
									</div>
									<div className="text">
										<motion.div
											initial={{
												opacity: 0,
												x: 300
											}}
											whileInView={{
												opacity: 1,
												x: 0,
												transition: {
													duration: 2
												}
											}}

										><p><span>GST Number:</span> <a href="#">09AAICN7487K1ZL</a></p></motion.div>

									</div>
								</div>
								<div className="dbox w-100 d-flex ">
									<div className="icon d-flex align-items-center justify-content-center">
										<span className="fa fa-phone"></span>
									</div>
									<div className="text">
										<motion.div
											initial={{
												opacity: 0,
												x: 300
											}}
											whileInView={{
												opacity: 1,
												x: 0,
												transition: {
													duration: 2
												}
											}}

										>
											<p><span>Phone:</span> <a href="tel://1234567920">+91 82099 06543</a></p>
										</motion.div>
									</div>
								</div>
								<div className="dbox w-100 d-flex ">
									<div className="icon d-flex align-items-center justify-content-center">
										<span className="fa fa-paper-plane"></span>
									</div>
									<div className="text">
										<motion.div
											initial={{
												opacity: 0,
												x: 300
											}}
											whileInView={{
												opacity: 1,
												x: 0,
												transition: {
													duration: 2
												}
											}}

										>
											<p><span>Email:</span> <a href="mailto:info@yoursite.com">Sales@newkartbusiness.com</a></p>
											 <a href="mailto:info@yoursite.com">enquiry@newkartbusiness.com</a>
										</motion.div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-12 mt-5 mb-5">
							<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3602.96315212185!2d78.58094968049943!3d25.439495419684732!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3977773ce247c3fd%3A0xbd433dda433b5376!2sGanj%20Rd%2C%20Sadar%20Bazar%2C%20Cantt%2C%20Jhansi%2C%20Uttar%20Pradesh%20284001!5e0!3m2!1sen!2sin!4v1708336849048!5m2!1sen!2sin" width="100%" height="400" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
						</div>
					</div>
				</div>
			</section>
			<Footer/>
		</>
	)
}

export default contact
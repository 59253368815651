import React from 'react'
import { Link } from 'react-router-dom';

const footer = () => {
	return (
		<footer className="ftco-footer">
			<div className="container mb-5 pb-4 footerdata">
				<div className="row">
					<div className="col-lg col-md-6">
						<div className="ftco-footer-widget">
							<h2 className="ftco-heading-2 d-flex align-items-center">About</h2>
							<p>At New Kart, we bring years of expertise to the world of import and export. Our mission is to provide comprehensive solutions that empower businesses to thrive in the global marketplace.</p>
							<ul className="ftco-footer-social list-unstyled mt-4">
								<li><a href="#"><span className="fa fa-twitter"></span></a></li>
								<li><a href="#"><span className="fa fa-facebook"></span></a></li>
								<li><a href="#"><span className="fa fa-instagram"></span></a></li>
							</ul>
						</div>
					</div>

					<div className="col-lg-2 col-md-6">
						<div className="ftco-footer-widget">
							<h2 className="ftco-heading-2">Links</h2>
							<div className="d-flex">
								<ul className="list-unstyled mr-md-4">
									<li><Link to="/"><span className="fa fa-chevron-right mr-2"></span>Home</Link></li>
									<li><Link to="/about"><span className="fa fa-chevron-right mr-2"></span>About Us</Link></li>
									<li><Link to="/ourteam"><span className="fa fa-chevron-right mr-2"></span>Our Team</Link></li>
									<li><Link to="/contact"><span className="fa fa-chevron-right mr-2"></span>Contact Us</Link></li>
								</ul>
							</div>
						</div>
					</div>

					<div className="col-lg col-md-6">
						<div className="ftco-footer-widget">
							<h2 className="ftco-heading-2">Services</h2>
							<ul className="list-unstyled">
								<li><Link to="/wedealin"><span className="fa fa-chevron-right mr-2"></span>We Deal IN Import & Export</Link></li>
							</ul>
						</div>
					</div>

					<div className="col-lg col-md-6">
						<div className="ftco-footer-widget">
							<h2 className="ftco-heading-2">Have a Questions?</h2>
							<div className="block-23 mb-3">
								<ul>
									<li><a href="#"><span className="fa fa-map-marker mr-3"></span><span className="text">211, RAMLILA GANJ, BALDEOJI MANDIR KE PASS, Sadar Bazar, Jhansi, Jhansi, Uttar Pradesh, 284001</span></a></li>
									<li><a href="#"><span className="fa fa-phone mr-3"></span><span className="text">+91 82099 06543</span></a></li>
									<li><a href="#"><span className="fa fa-paper-plane mr-3"></span><span className="text">enquiry@newkartbusiness.com</span></a></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="container-fluid bg-primary">
				<div className="container">
					<div className="row">
						<div className="col-md-6 aside-stretch py-3">

							<p className="mb-0">
								Copyright &copy;<script>document.write(new Date().getFullYear());</script> All rights reserved | This template is made with <i className="fa fa-heart" aria-hidden="true"></i> by <a href="https://codeyesinfotech.com/" target="_blank">Codeyes Infotech</a>
							</p>
						</div>
					</div>
				</div>
			</div>
		</footer>
	)
}

export default footer
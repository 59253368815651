import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenMenu, setIsOpenMenu] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const toggleMobileMenu = () => {
        setIsOpenMenu(!isOpenMenu);
    };

    return (
        <>
            <nav className="navbar bg-white navbar-expand-lg navbar-dark bg-dark ftco-navbar-light" id="ftco-navbar">
                <div className="w-100 py-3 px-5 d-flex align-items-center">
                    <div className="collapse navbar-collapse" id="ftco-nav">
                        <div className="col-md-4 d-flex mb-2 mb-md-0">
                            <NavLink className="navbar-brand d-flex align-items-center" to="/">
                                <span className="ml-2 text-black">Newkart<small>Business Pvt. Ltd </small></span>
                            </NavLink>
                        </div>
                        <ul className="navbar-nav mr-auto">
                            <li className="nav-item">
                                <NavLink exact to="/" className="nav-link" activeClassName="active">Home</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink exact to="/wedealin" className="nav-link" activeClassName="active">We Deal In Import & Export</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/about" className="nav-link" activeClassName="active">About Us</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink exact to="/ourteam" className="nav-link" activeClassName="active">Our Team</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/contact" className="nav-link" activeClassName="active">Contact</NavLink>
                            </li>
                        </ul>
                        <div className={`menu ${isOpen ? 'opened' : ''}`} aria-expanded={isOpen} onClick={toggleMenu} aria-label="Main Menu">
                            <svg width="30" height="30" viewBox="0 0 100 100">
                                <path className="line line1" d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058" />
                                <path className="line line2" d="M 20,50 H 80" />
                                <path className="line line3" d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942" />
                            </svg>
                        </div>
                    </div>
                </div>
            </nav>

            <div className="menubarmobile">
                <div className="logofmobile">
                    <div className="col-md-4 d-flex mb-2 mb-md-0">
                        <NavLink className="navbar-brand d-flex align-items-center" to="/">
                            <span className="ml-2 text-white">Newkart<small>Business Pvt. Ltd </small></span>
                        </NavLink>
                    </div>
                    <div className={`menu ${isOpenMenu ? 'opened' : ''}`} aria-expanded={isOpenMenu} onClick={toggleMobileMenu} aria-label="Main Menu">
                        <svg width="30" height="30" viewBox="0 0 100 100">
                            <path className="line line1" d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058" />
                            <path className="line line2" d="M 20,50 H 80" />
                            <path className="line line3" d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942" />
                        </svg>
                    </div>
                </div>

                <AnimatePresence>
                    {isOpenMenu && (
                        <motion.div
                            initial="closed"
                            animate="open"
                            exit="closed"
                            variants={{
                                open: { x: 0 },
                                closed: { x: '-100%' },
                            }}
                            transition={{ ease: 'easeOut', duration: 0.2 }}
                        >
                            <div className='moblemenunavlink'>
                                <ul>
                                    <li>
                                        <NavLink exact to="/" className="nav-link" activeClassName="active">Home</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink exact to="/wedealin" className="nav-link" activeClassName="active">We Deal In Import & Export</NavLink>
                                    </li>

                                    <li className="nav-item">
                                        <NavLink exact to="/ourteam" className="nav-link" activeClassName="active">Our Team</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/about" className="nav-link" activeClassName="active">About Us</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/contact" className="nav-link" activeClassName="active">Contact</NavLink>
                                    </li>
                                </ul>
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>

            <AnimatePresence>
                {isOpen && (
                    <motion.div
                        className="navbarmenuessection"
                        initial="closed"
                        animate="open"
                        exit="closed"
                        variants={{
                            open: { x: 0 },
                            closed: { x: '100%' },
                        }}
                        transition={{ ease: 'easeOut', duration: 0.2 }}
                    >
                        <div className={`menu ${isOpen ? 'opened' : ''}`} id='sidebarcloseicon' aria-expanded={isOpen} onClick={toggleMenu} aria-label="Main Menu">
                            <svg width="30" height="30" viewBox="0 0 100 100">
                                <path className="line line1" d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058" />
                                <path className="line line2" d="M 20,50 H 80" />
                                <path className="line line3" d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942" />
                            </svg>
                        </div>

                        <div className='sitesidebar'>
                            <h4>CONTACT US</h4>
                            <h1>HAPPY TO ANSWER<br />
                                YOUR QUESTIONS</h1>
                            <h3>Call us for all of your questions today.</h3>
                            <h5>CONTACT</h5>
                            <p>manufaktur@example.com</p>

                            <div className="col-sm justify-content-center d-flex mb-md-0 mb-2" id='sidebarsocialmediaicon'>
                                <div className="social-media">
                                    <p className="mb-0 d-flex">
                                        <a href="#" className="d-flex align-items-center justify-content-center"><span className="fa fa-facebook"><i className="sr-only">Facebook</i></span></a>
                                        <a href="#" className="d-flex align-items-center justify-content-center"><span className="fa fa-twitter"><i className="sr-only">Twitter</i></span></a>
                                        <a href="#" className="d-flex align-items-center justify-content-center"><span className="fa fa-instagram"><i className="sr-only">Instagram</i></span></a>
                                        <a href="#" className="d-flex align-items-center justify-content-center"><span className="fa fa-dribbble"><i className="sr-only">Dribbble</i></span></a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </>
    );
};

export default Navbar;

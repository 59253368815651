import React, { useState } from 'react';
import { motion } from "framer-motion";
import { Link } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Footer from '../components/footer';
import Navbar from '../components/navbar';
import TopBar from '../components/topbar';
import { ProductRange,PackagingSolution,WhyChooseUs,SingleProduct1,SingleProduct2,SingleProduct3,QualityCheck } from '../data';
import Background2 from '../assets/images/about.jpg';
import Background4 from '../assets/images/bg_2.jpg';
import M1 from '../assets/images/home/TMT-bars.webp';
import M4 from '../assets/images/home/angle-home.webp';
import Background1 from '../assets/images/home/background.png';
import Bannericon from '../assets/images/home/bannericon.png';
import M5 from '../assets/images/home/beams-home.webp';
import Certified from '../assets/images/home/certified.png';
import M3 from '../assets/images/home/channel-home.webp';
import M6 from '../assets/images/home/flats-bars-home.webp';
import M2 from '../assets/images/home/sheets-and-plats-home.webp';
import Background5 from '../assets/images/about-3.jpg';


const Home = () => {
    const [openIndex, setOpenIndex] = useState(0); // Set the default open index to 0 (first question)

    // Function to toggle open/close state of FAQ item at given index
    const toggleItem = (index) => {
      setOpenIndex(prevIndex => (prevIndex === index ? null : index));
    };

    return (
        <>
            <TopBar />
            <Navbar />
            <section className="hero-wrap js-fullheight" style={{ backgroundImage: `url(${Background1})`, height: "700px" }} data-stellar-background-ratio="0.5">
                <div className="container">
                    <div className="row no-gutters slider-text js-fullheight align-items-center" data-scrollax-parent="true">
                        <motion.div
                            className="col-lg-6 "
                            initial={{
                                opacity: 0,
                                x: -10
                            }}
                            whileInView={{
                                opacity: 1,
                                x: 0,
                                transition: {
                                    duration: 1
                                }
                            }}

                        >
                            <div className="mt-5">
                                <h1 className="mb-2">New Kart Business Pvt.</h1>
                                <h3 className="mb-4">Welcome to New Kart Business Private Ltd.</h3>
                                <p className="mb-4">Elevate Your Business with Exceptional Import and Export Solutions.</p>
                                <p><Link to="/wedealin" className="btn btn-primary">We Deal In Import & Export</Link></p>
                            </div>

                        </motion.div>


                        <motion.div
                            className="col-lg-6 "
                            initial={{
                                opacity: 0,
                                // if odd index card,slide from right instead of left
                                x: 10
                            }}
                            whileInView={{
                                opacity: 1,
                                x: 0, // Slide in to its original position
                                transition: {
                                    duration: 1 // Animation duration
                                }
                            }}

                        >

                            <div className="text-center">
                                <img src={Bannericon} />
                            </div>
                        </motion.div>
                    </div>
                </div>
            </section>

            <section className="ftco-section p-5" id="about-section">
                <div className="container">
                    <div className="row">
                        <motion.div
                            className="col-md-6 d-flex align-items-stretch"
                            initial={{
                                opacity: 0,
                                x: 10
                            }}
                            whileInView={{
                                opacity: 1,
                                x: 0,
                                transition: {
                                    duration: 1
                                }
                            }}
                        >
                            <div className="about-wrap img w-100" style={{ backgroundImage: `url(${Background2})` }}>
                                <div className="icon d-flex align-items-center justify-content-center" style={{ height: '100px', boxShadow: 'none' }}><img src={Certified} style={{ height: '100px' }} /></div>
                            </div>
                        </motion.div>


                        <motion.div
                            className="col-md-6 py-5 pl-md-5"
                            initial={{
                                opacity: 0,
                                x: -10
                            }}
                            whileInView={{
                                opacity: 1,
                                x: 0,
                                transition: {
                                    duration: 1
                                }
                            }}
                        >
                            <div className="row justify-content-center mb-4 pt-md-4">
                                <div className="col-md-12 heading-section ">
                                    <span className="subheading">Welcome to New Kart Business Pvt.</span>
                                    <h2 className="mb-4 text-white">Your Trusted Partner in Global Trade</h2>
                                    <div className="d-flex about">
                                        <h3 className="text-white">Elevate Your Business with Exceptional Import and Export Solutions</h3>
                                    </div>
                                    <p className="text-white">At Newkart Business Pvt. Ltd, we are dedicated to facilitating seamless import and export solutions, connecting businesses across the world. Based in India, we specialize in a diverse range of products to meet the ever-evolving demands of international trade.</p>
                                </div>
                            </div>
                        </motion.div>
                    </div>
                </div>
            </section>

            <div className="py-5 container">
                <div className=" text-center heading-section mb-5">
                    <span className="subheading text-center">Newkart Business Pvt. Ltd</span>
                    <h2 className="mb-1 text-center">Our Product Range Includes</h2>
                </div>

                <div className="row">
                    <Carousel
                        additionalTransfrom={0}
                        arrows
                        autoPlay
                        autoPlaySpeed={3000}
                        centerMode={false}
                        containerClass="container-with-dots"
                        dotListClass=""
                        draggable
                        focusOnSelect={false}
                        infinite={false}
                        itemClass=""
                        keyBoardControl
                        minimumTouchDrag={80}
                        pauseOnHover
                        renderArrowsWhenDisabled={false}
                        renderButtonGroupOutside={false}
                        renderDotsOutside={false}
                        responsive={{
                            desktop: {
                                breakpoint: {
                                    max: 3000,
                                    min: 1024
                                },
                                items: 3,
                                partialVisibilityGutter: 40
                            },
                            mobile: {
                                breakpoint: {
                                    max: 464,
                                    min: 0
                                },
                                items: 1,
                                partialVisibilityGutter: 30
                            },
                            tablet: {
                                breakpoint: {
                                    max: 1024,
                                    min: 464
                                },
                                items: 2,
                                partialVisibilityGutter: 30
                            }
                        }}
                        rewind
                        rewindWithAnimation={false}
                        rtl={false}
                        shouldResetAutoplay
                        showDots={false}
                        sliderClass=""
                        slidesToSlide={2}
                        swipeable
                    >

                        {ProductRange.map((data, i) => {
                            return (
                                <div key={i} className="">
                                    <div className="services-wrap" style={{ height: "400px" }}>
                                        <div className="img immbg" style={{ backgroundImage: `url(${data.img})` }}></div>
                                        <div className="text">
                                            <h2>{data.title}</h2>
                                            <p>{data.decription}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </Carousel>
                </div>
            </div>

            <div className="py-5 " id="packaging-section">
                <div className=" text-center heading-section mb-5">
                    <span className="subheading text-center">Packaging Solutions for Various Industries</span>
                    <h2 className="mb-1 text-center text-white">We also specialize in providing premium packaging materials tailored for the following industries</h2>
                </div>
                <div className="gallery">
                        {PackagingSolution.map((data, i) => {
                            return (
                                <div key={i} className="services-wrap">
                                    <div className="image-container">
                                        <div className="image" style={{ backgroundImage: `url(${data.img})` }}></div>
                                         <div className="overlay"></div>
                                        <div className="text">
                                            <h2 className='bg-white py-2'>{data.title}</h2>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                
                </div>
            </div>

            <section className="ftco-section ftco-no-pt ftco-no-pb ftco-counter">
                <div className="img image-overlay" style={{ backgroundImage: `url(${Background5})` }}></div>
                <div className="container">
                    <div className="row no-gutters">
                        <div className="col-md-6 py-5 bg-secondary aside-stretch">
                            <div className="heading-section heading-section-white p-4 pl-md-0 py-md-5 pr-md-5">
                                <span className="subheading">Why Choose</span>
                                <h2 className="mb-4">Why Choose New Kart Business Private Ltd.?</h2>
                                {WhyChooseUs.map((data, index) => (
                                    <div key={index}  className={`faq-item ${openIndex === index ? 'open' : ''}`} onClick={() => toggleItem(index)}>
                                        <div className="question text-white"><svg style={{ marginRight: "15px" }} xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-circle-fill" viewBox="0 0 16 16">
                                            <circle cx="8" cy="8" r="8" />
                                        </svg>{data.title}</div>
                                        <div className={`answer ${openIndex === index ? 'open' : ''}`}>
                                            {openIndex === index && <p>{data.description}</p>}
                                        </div>
                                    </div>
                                ))}
                            </div>

                        </div>
                        <div className="col-md-6 d-flex align-items-center">
                            <div className="row">
                            </div>
                        </div>
                    </div>
                </div >
            </section >



            {/* Start Best sellers Area */}
            {/* < section className="best-seller-area ftco-section pt-6 pb-1" >
                <div className="container">
                    <div className="row justify-content-center mb-5 pb-3">
                        <div className="col-md-7 text-center heading-section mb-5">
                            <motion.div
                                initial={{
                                    opacity: 0,
                                    y: 100
                                }}
                                whileInView={{
                                    opacity: 1,
                                    y: 0,
                                    transition: {
                                        duration: 1
                                    }
                                }}
                            >
                                <span className="subheading">Among all products, the best sellers.</span>
                            </motion.div>

                            <motion.div
                                initial={{
                                    opacity: 0,
                                }}
                                whileInView={{
                                    opacity: 1,
                                    transition: {
                                        duration: 2
                                    }
                                }}
                            >
                                <h2 className="mb-1">Best Sellers</h2>

                            </motion.div>

                            <motion.div
                                initial={{
                                    opacity: 0,
                                    y: 100
                                }}
                                whileInView={{
                                    opacity: 1,
                                    y: 0,
                                    transition: {
                                        duration: 1
                                    }
                                }}

                            >
                                <p>Sourcing high grade steel from the most trusted mills in India, we also specialize in tailor made offerings based on your specifications.</p>
                            </motion.div>
                        </div>
                        <Carousel
                            additionalTransfrom={0}
                            arrows
                            autoPlay
                            autoPlaySpeed={3000}
                            centerMode={false}
                            className=""
                            containerClass="container-with-dots"
                            dotListClass=""
                            draggable
                            focusOnSelect={false}
                            infinite={false}
                            itemClass=""
                            keyBoardControl
                            minimumTouchDrag={80}
                            pauseOnHover
                            renderArrowsWhenDisabled={false}
                            renderButtonGroupOutside={false}
                            renderDotsOutside={false}
                            responsive={{
                                desktop: {
                                    breakpoint: {
                                        max: 3000,
                                        min: 1024
                                    },
                                    items: 3,
                                    partialVisibilityGutter: 40
                                },
                                mobile: {
                                    breakpoint: {
                                        max: 464,
                                        min: 0
                                    },
                                    items: 1,
                                    partialVisibilityGutter: 30
                                },
                                tablet: {
                                    breakpoint: {
                                        max: 1024,
                                        min: 464
                                    },
                                    items: 2,
                                    partialVisibilityGutter: 30
                                }
                            }}
                            rewind
                            rewindWithAnimation={false}
                            rtl={false}
                            shouldResetAutoplay
                            showDots={false}
                            sliderClass=""
                            slidesToSlide={2}
                            swipeable
                        >

                            {SingleProduct1.map((data, i) => {
                                return (
                                    <div key={i} className="">
                                        <div className="services-wrap ">
                                            <div className="img" style={{ backgroundImage: `url(${data.img})` }}></div>
                                            <div className="text">
                                                <h5 className="text-start" style={{ fontWeight: "normal" }}>{data.title}</h5>
                                                <ul className="products-rating d-flex p-0">
                                                    <li style={{ listStyleType: 'none' }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                        </svg>
                                                    </li>
                                                    <li style={{ listStyleType: 'none' }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                        </svg>
                                                    </li>
                                                    <li style={{ listStyleType: 'none' }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                        </svg>
                                                    </li>
                                                    <li style={{ listStyleType: 'none' }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                        </svg>
                                                    </li>
                                                    <li style={{ listStyleType: 'none' }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                        </svg>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </Carousel>

                        <Carousel
                            additionalTransfrom={0}
                            arrows
                            autoPlay
                            autoPlaySpeed={3000}
                            centerMode={false}
                            className=""
                            containerClass="container-with-dots"
                            dotListClass=""
                            draggable
                            focusOnSelect={false}
                            infinite={false}
                            itemClass=""
                            keyBoardControl
                            minimumTouchDrag={80}
                            pauseOnHover
                            renderArrowsWhenDisabled={false}
                            renderButtonGroupOutside={false}
                            renderDotsOutside={false}
                            responsive={{
                                desktop: {
                                    breakpoint: {
                                        max: 3000,
                                        min: 1024
                                    },
                                    items: 3,
                                    partialVisibilityGutter: 40
                                },
                                mobile: {
                                    breakpoint: {
                                        max: 464,
                                        min: 0
                                    },
                                    items: 1,
                                    partialVisibilityGutter: 30
                                },
                                tablet: {
                                    breakpoint: {
                                        max: 1024,
                                        min: 464
                                    },
                                    items: 2,
                                    partialVisibilityGutter: 30
                                }
                            }}
                            rewind
                            rewindWithAnimation={false}
                            rtl={false}
                            shouldResetAutoplay
                            showDots={false}
                            sliderClass=""
                            slidesToSlide={2}
                            swipeable
                        >

                            {SingleProduct2.map((data, i) => {
                                return (
                                    <div key={i} className="">
                                        <div className="services-wrap ">
                                            <div className="img" style={{ backgroundImage: `url(${data.img})` }}></div>
                                            <div className="text">
                                                <h5 className="text-start" style={{ fontWeight: "normal" }}>{data.title}</h5>
                                                <ul className="products-rating d-flex p-0">
                                                    <li style={{ listStyleType: 'none' }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                        </svg>
                                                    </li>
                                                    <li style={{ listStyleType: 'none' }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                        </svg>
                                                    </li>
                                                    <li style={{ listStyleType: 'none' }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                        </svg>
                                                    </li>
                                                    <li style={{ listStyleType: 'none' }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                        </svg>
                                                    </li>
                                                    <li style={{ listStyleType: 'none' }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                        </svg>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </Carousel>

                        <Carousel
                            additionalTransfrom={0}
                            arrows
                            autoPlay
                            autoPlaySpeed={3000}
                            centerMode={false}
                            className=""
                            containerClass="container-with-dots"
                            dotListClass=""
                            draggable
                            focusOnSelect={false}
                            infinite={false}
                            itemClass=""
                            keyBoardControl
                            minimumTouchDrag={80}
                            pauseOnHover
                            renderArrowsWhenDisabled={false}
                            renderButtonGroupOutside={false}
                            renderDotsOutside={false}
                            responsive={{
                                desktop: {
                                    breakpoint: {
                                        max: 3000,
                                        min: 1024
                                    },
                                    items: 3,
                                    partialVisibilityGutter: 40
                                },
                                mobile: {
                                    breakpoint: {
                                        max: 464,
                                        min: 0
                                    },
                                    items: 1,
                                    partialVisibilityGutter: 30
                                },
                                tablet: {
                                    breakpoint: {
                                        max: 1024,
                                        min: 464
                                    },
                                    items: 2,
                                    partialVisibilityGutter: 30
                                }
                            }}
                            rewind
                            rewindWithAnimation={false}
                            rtl={false}
                            shouldResetAutoplay
                            showDots={false}
                            sliderClass=""
                            slidesToSlide={2}
                            swipeable
                        >

                            {SingleProduct3.map((data, i) => {
                                return (
                                    <div key={i} className="">
                                        <div className="services-wrap ">
                                            <div className="img" style={{ backgroundImage: `url(${data.img})` }}></div>
                                            <div className="text">
                                                <h5 className="text-start" style={{ fontWeight: "normal" }}>{data.title}</h5>
                                                <ul className="products-rating d-flex p-0">
                                                    <li style={{ listStyleType: 'none' }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                        </svg>
                                                    </li>
                                                    <li style={{ listStyleType: 'none' }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                        </svg>
                                                    </li>
                                                    <li style={{ listStyleType: 'none' }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                        </svg>
                                                    </li>
                                                    <li style={{ listStyleType: 'none' }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                        </svg>
                                                    </li>
                                                    <li style={{ listStyleType: 'none' }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                        </svg>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </Carousel>

                    </div>
                </div>
            </section > */}
            {/* End Best sellers Area */}




            < section className="ftco-section p-5 bg-half-light" id="backgroundimgset" >
                <div className="container">
                    <div className="row justify-content-center mb-5 pb-2">
                        <div className="col-md-8 text-center heading-section ">
                            <motion.div
                                initial={{
                                    opacity: 0,
                                    y: 100
                                }}
                                whileInView={{
                                    opacity: 1,
                                    y: 0,
                                    transition: {
                                        duration: 1
                                    }
                                }}

                            >
                                <span className="subheading" style={{ fontSize: '16px' }}>When you need
                                    particular steel at a
                                    particular place within a
                                    particular time, our role begins.</span>

                            </motion.div>

                            <motion.div
                                initial={{
                                    opacity: 0,
                                }}
                                whileInView={{
                                    opacity: 1,
                                    transition: {
                                        duration: 2
                                    }
                                }}

                            >
                                <h2 className="mb-4 text-white">We have everything in place to ensure that your orders reach your doorsteps within the expected time.</h2>
                            </motion.div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="services-wrap w-100">
                                <div className="img" style={{ backgroundImage: `url(${M1})` }}></div>
                                <div className="text">
                                    <h2>TMT & Rebars</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="services-wrap w-100">
                                <div className="img" style={{ backgroundImage: `url(${M2})` }}></div>
                                <div className="text">

                                    <h2>Sheets & Plates</h2>


                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="services-wrap w-100">
                                <div className="img" style={{ backgroundImage: `url(${M3})` }}></div>
                                <div className="text">
                                    <h2>Channels</h2>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4">
                            <div className="services-wrap w-100 ">
                                <div className="img" style={{ backgroundImage: `url(${M4})` }}></div>
                                <div className="text">
                                    <h2>Angles</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="services-wrap w-100 ">
                                <div className="img" style={{ backgroundImage: `url(${M5})` }}></div>
                                <div className="text">

                                    <h2>Beams</h2>


                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="services-wrap w-100">
                                <div className="img" style={{ backgroundImage: `url(${M6})` }}></div>
                                <div className="text">
                                    <h2>Flat Bars</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >

            <section className="ftco-section">
                <div className="container">
                    <div className="row justify-content-center mb-5 pb-3">
                        <div className="col-md-7 text-center heading-section ">
                            <motion.div
                                initial={{
                                    opacity: 0,
                                    y: 100
                                }}
                                whileInView={{
                                    opacity: 1,
                                    y: 0,
                                    transition: {
                                        duration: 1
                                    }
                                }}
                            >
                                <span className="subheading">NEW KART BUSINESS PVT.</span>
                            </motion.div>

                            <motion.div
                                initial={{
                                    opacity: 0,
                                }}
                                whileInView={{
                                    opacity: 1,
                                    transition: {
                                        duration: 2
                                    }
                                }}
                            >
                                <h2 className="mb-1">Quality
                                    & Choice.</h2>

                            </motion.div>

                            <motion.div
                                initial={{
                                    opacity: 0,
                                    y: 100
                                }}
                                whileInView={{
                                    opacity: 1,
                                    y: 0,
                                    transition: {
                                        duration: 1
                                    }
                                }}

                            >
                                <p>Sourcing high grade steel from the most trusted mills in India, we also specialize in tailor made offerings based on your specifications.</p>
                            </motion.div>
                        </div>
                    </div>
                    <div className="projectlogo">

                        {QualityCheck.map((data, i) => {
                            return (
                                <div key={i} className="col-md-3">
                                    <div className="project2">
                                        <img src={data.logo}>
                                        </img>
                                    </div>
                                </div>
                            )
                        })}


                    </div>
                </div>
            </section>

            <section className="ftco-intro">
                <div className="row justify-content-center">
                    <div className="col-md-12 text-center">
                        <div className="img" style={{ backgroundImage: `url(${Background4})` }}>
                            <div className="overlay"></div>
                            <h2>Providing Personalized and High Quality Services</h2>
                            <p>We can manage your dream building A small river named Duden flows by their place</p>
                            <motion.div
                                initial={{
                                    opacity: 0,
                                    y: 100
                                }}
                                whileInView={{
                                    opacity: 1,
                                    y: 0,
                                    transition: {
                                        duration: 1
                                    }
                                }}

                            >
                                <p className="mb-0"><Link to="/contact" className="btn btn-primary px-4 py-3">Contact Us</Link></p>

                            </motion.div>

                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default Home
import React, { useState } from 'react'
import Navbar from '../components/navbar'
import Topbar from '../components/topbar'
import Footer from '../components/footer'
import { ProductData } from '../data'
import { motion } from "framer-motion";
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import Background1 from '../assets/images/banner/wedeal4.jpg';
import Background2 from '../assets/images/banner/wedeal.jpg';

const Wedealin = () => {
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedSubcategory, setSelectedSubcategory] = useState(null);

    if (!selectedCategory && ProductData.length > 0) {
        setSelectedCategory(ProductData[0].category);
        setSelectedSubcategory(ProductData[0].subcategories[0]);
    }

    const handleCategoryClick = category => {
        setSelectedCategory(category);
        const categoryData = ProductData.find(item => item.category === category);
        if (categoryData && categoryData.subcategories && categoryData.subcategories.length > 0) {
            setSelectedSubcategory(categoryData.subcategories[0]);
        } else {
            setSelectedSubcategory(null);
        }
    };

    const handleSubcategoryClick = subcategory => {
        setSelectedSubcategory(subcategory);
    };
    return (
        <>
            <Topbar />
            <Navbar />

            <section className="hero-wrap hero-wrap-2" style={{ backgroundImage: `url(${Background1})`, backgroundSize: "cover", width: "100%" }} data-stellar-background-ratio="0.5">
                <div className="overlay"></div>
                <div className="container">
                    <div className="row no-gutters slider-text align-items-end justify-content-start">
                        <div className="col-md-9  pb-5">
                            <p className="breadcrumbs"><span className="mr-2"><a href="/">Home <i className="fa fa-chevron-right"></i></a></span> <span>We Deal In Import & Export <i className="fa fa-chevron-right"></i></span></p>
                            <motion.div
                                initial={{
                                    opacity: 0,
                                    x: -50
                                }}
                                whileInView={{
                                    opacity: 1,
                                    x: 0,
                                    transition: {
                                        duration: 1
                                    }
                                }}

                            >
                                <h1 className="mb-3 bread">We Deal In Import & Export</h1>
                            </motion.div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="container">
                <div className="  importexportdeal">
                    <div className="col-md-6 importfirstdiv d-flex align-items-stretch">
                        <img className="img " src={Background2} />
                    </div>
                    <motion.div
                        className=" py-5 pl-md-5"
                        initial={{
                            opacity: 0,
                            x: 50
                        }}
                        whileInView={{
                            opacity: 1,
                            x: 0,
                            transition: {
                                duration: 1
                            }
                        }}
                    >
                        <div className="row justify-content-center mb-4 pt-md-4">
                            <div className="col-md-12 heading-section ">
                                <span className="subheading">Welcome to New Kart Business Pvt.</span>
                                <h2 className="mb-2" style={{ fontSize: "30px" }}>We Deal in Export and Import of </h2>
                                <p style={{ fontSize: "16.5px" }}>We specialize in the export and import of a diverse range of products, facilitating seamless global trade. Our portfolio encompasses high-quality steels, intricate handicrafts, precision pipe and pipe-fitting solutions, safety items, fasteners, wire mesh, gratings, and vibrating screens. Additionally, we offer rubber and synthetic rubber products, refractory anchors, stainless steel, HR coils, PPGI coils, CR coils, non-ferrous metals, exquisite handicrafts, textiles, Insta Reels making accessories, food products, pumps, electrical cables, and specialized packaging materials. At Newkart Business Pvt. Ltd, we are your trusted partner in navigating the complexities of international commerce, ensuring reliability and excellence in every transaction.</p>
                            </div>
                        </div>
                    </motion.div>
                </div>
            </section>

            <section className='wedealimportexport'>
                <div className='container'>
                    <div className="col-md-12 heading-section ">
                        <span className="subheading">Welcome to New Kart Business Pvt.</span>
                        <h2 className="mb-2" style={{ fontSize: "30px" }}>Below are the details of our Product range</h2>
                    </div>

                    <select className="dropdown" onChange={(e) => handleCategoryClick(e.target.value)}>
                        {ProductData.map(category => (
                            <option key={category.category} value={category.category}>
                                {category.category}
                            </option>
                        ))}
                    </select>
                    <div className='deal1'>
                        {ProductData.map(category => (
                            <button key={category.category} onClick={() => handleCategoryClick(category.category)} className={selectedCategory === category.category ? 'active' : ''}>
                                {category.category}
                            </button>
                        ))}
                    </div>
                    {selectedCategory && (
                        <div className='deal2'>
                            <div className='dealsub'>
                                <h2>{selectedCategory}</h2>
                                <div className='d-flex'>
                                    {(ProductData.find(category => category.category === selectedCategory).subcategories || []).map(subcategory => (
                                        <div key={subcategory.name}>
                                            <button onClick={() => handleSubcategoryClick(subcategory)} className={selectedSubcategory === subcategory ? 'active' : ''}>
                                                {subcategory.name}
                                            </button>
                                        </div>
                                    ))}
                                </div>

                            </div>
                            {(ProductData.find(category => category.category === selectedCategory).products || '') && (
                                <div className='wedealproductdetails'>
                                    <p>{ProductData.find(category => category.category === selectedCategory).products}</p>
                                </div>
                            )}

                            {selectedCategory && selectedSubcategory && (
                                <div className='wedealsubcategories'>
                                    <div className='wedealsubcat2'>
                                        <ImageGallery thumbnailPosition={"left"} showFullscreenButton={false} showPlayButton={false} items={selectedSubcategory.images} />
                                        <div className='wedealsucatcat'>
                                            <div className="heading-section ">
                                                <span className="subheading">New Kart Business Pvt.</span>
                                                <h2 className="mb-1" >{selectedSubcategory.name}</h2>
                                            </div>
                                            <p>{selectedSubcategory.description}</p>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </section>

            <Footer />

        </>
    )
}

export default Wedealin
import { motion } from "framer-motion";
import React from 'react';
import Footer from '../components/footer';
import Navbar from '../components/navbar';
import TopBar from '../components/topbar';
import { default as Background1, default as Background4 } from '../assets/images/banner/back2.jpg';
import Background6 from '../assets/images/team-9.jpg';
import { Link } from 'react-router-dom';
import { QualityCheck } from '../data'

const ourteam = () => {
  return (
    <>
      <TopBar />
      <Navbar />
      <section className="hero-wrap hero-wrap-2" style={{ backgroundImage: `url(${Background1})`, backgroundPosition: "top" }} data-stellar-background-ratio="0.5">
        <div className="overlay"></div>
        <div className="container">
          <div className="row no-gutters slider-text align-items-end justify-content-start">
            <div className="col-md-9  pb-5">
              <p className="breadcrumbs"><span className="mr-2"><a href="/">Home <i className="fa fa-chevron-right"></i></a></span> <span>Our Team<i className="fa fa-chevron-right"></i></span></p>
              <motion.div
                initial={{
                  opacity: 0,
                  x: -300
                }}
                whileInView={{
                  opacity: 1,
                  x: 0,
                  transition: {
                    duration: 1
                  }
                }}

              >
                <h1 className="mb-3 bread">Our Team</h1>
              </motion.div>
            </div>
          </div>
        </div>
      </section>


      <div className="container">
        <div className="ourteamdirector">
          <div className="directorimg">
            <img src={Background6} />
            <div className="directorname">
              <h5>Jayesh</h5>
              <p>Director, Marketing & Sales</p>
            </div>
          </div>
          <div className="ourteamdirectorsub">
            <div className="text-center heading-section ">
              <span className="subheading">New Kart Business Pvt.</span>
              <h2>Director - Marketing & Sales</h2>
            </div>
            <p className="text-justify directordetails">Hello, I’m Jayesh and I’m the Director, Sales & Marketing at Newkart Business Pvt Ltd.
              With a diverse experience portfolio in the field of Engineering, Procurement, Management, Hospitality & Fitness along with general well being, I bring a wealth of knowledge, skills, efficiency & an exploration drive to our team. I’m passionate and inquisitive about the general along with specific requirements of my clients and I’m committed to providing the highest level of Service to address our clients.
              To talk about experience, I have a proven track record of my experience in the field of Engineering & Procurement along with Safety and management, which came after I completed my studies in Engineering.
              Also, I have worked closely in the hospitality industry which gives me an expert eye to assess the requirement of my clients.
              At Newkart Business Pvt Ltd, I’m focused on exploring the international market’s growing demand for various products available in India and elsewhere which would most certainly be facilitated by my connections in and out of the country.
              In addition to our domestic business of supplying structural steel to our various esteemed clients, I work closely with our team to ensure that we deliver exceptional services to our clients. I’m excited to be part of such a dynamic and innovative company, and I look forward to helping our clients have their requirements addressed and fulfilled.</p>
          </div>
        </div>
      </div>

      <div className="py-2">
        <div className=" container aboutmission" >
          <div className="text-center heading-section my-2 w-100">
            <span className="subheading">Workforce</span>
            <h3 className="mb-0 text-black" style={{fontSize:"20px"}}>"Our dedicated team of 20 professionals forms the backbone of Newkart Business Pvt. Ltd. Each member contributes unique skills and expertise, collectively fostering a culture of innovation and customer-centricity. Together, we are committed to upholding the company's values, delivering exceptional products and services, and realizing our vision of becoming a global leader in the import and export industry."</h3>
          </div>
        </div>
      </div>


      <section class="ftco-section">
        <div class="container">
          <div class="row justify-content-center mb-0 pb-3">
            <div class="col-md-7 text-center heading-section ">
              <span class="subheading">Company Name</span>
              <h2 class="mb-4">Our Team</h2>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-sm-6">
              <div class="block-2 ">
                <div class="flipper">
                  <div class="front" style={{ backgroundImage: `url(${Background6})`, backgroundPosition: "top" }}>
                    <div class="box">
                      <h2>Rohit Mittal </h2>
                      <p>Managing Director</p>
                    </div>
                  </div>
                  <div class="back">

                    <blockquote>
                      <p>&ldquo;Rohit Mittal, an accomplished engineer with 8 years of industry expertise, brings a wealth of knowledge to the role of Managing Director. His career has been marked by strategic vision and leadership, propelling the company towards new heights. With a keen understanding of industry trends, Rohit oversees the overall direction and growth strategies of Newkart.&rdquo;</p>
                    </blockquote>
                    <div class="author d-flex">
                      <div class="image align-self-center">
                        <img src="images/team-1.jpg" alt="" />
                      </div>
                      <div class="name align-self-center ml-3">Rohit Mittal <span class="position">Managing Director</span></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4   col-sm-6">
              <div class="block-2 ">
                <div class="flipper">
                  <div class="front" style={{ backgroundImage: `url(${Background6})`, backgroundPosition: "top" }}>
                    <div class="box">
                      <h2>Jayesh Purohit </h2>
                      <p>Director - Sales and Marketing</p>
                    </div>
                  </div>
                  <div class="back">

                    <blockquote>
                      <p>&ldquo;Jayesh Purohit, an engineer with 8 years of specialized experience in sales and marketing, serves as the Director overseeing these crucial domains. His career has been characterized by successful market penetration strategies and a deep understanding of customer needs. Jayesh plays a pivotal role in shaping the company's market presence and ensuring customer satisfaction.&rdquo;</p>
                    </blockquote>
                    <div class="author d-flex">
                      <div class="image align-self-center">
                        <img src="images/team-6.jpg" alt="" />
                      </div>
                      <div class="name align-self-center ml-3">Jayesh Purohit <span class="position">Director - Sales and Marketing   </span></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4   col-sm-6">
              <div class="block-2 ">
                <div class="flipper">
                  <div class="front" style={{ backgroundImage: `url(${Background6})`, backgroundPosition: "top" }}>
                    <div class="box">
                      <h2>Himanshu Singh </h2>
                      <p>Director - Operations & SCM</p>
                    </div>
                  </div>
                  <div class="back">

                    <blockquote>
                      <p>&ldquo;Himanshu Singh, a seasoned finance professional with 7 years of proven expertise, leads as the Director of Operations & Supply Chain Management. His background in finance equips him with a meticulous approach to operations, ensuring efficiency and excellence. Himanshu plays a key role in streamlining processes, optimizing the supply chain, and enhancing overall operational performance.&rdquo;</p>
                    </blockquote>
                    <div class="author d-flex">
                      <div class="image align-self-center">
                        <img src="images/team-7.jpg" alt="" />
                      </div>
                      <div class="name align-self-center ml-3">Himanshu Singh <span class="position">Director - Operations & SCM</span></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>



      <section className="ftco-section pt-0">
        <div className="container">
          <div className="row justify-content-center mb-5 pb-3">
            <div className="col-md-7 text-center heading-section ">
              <motion.div
                initial={{
                  opacity: 0,
                  y: 100
                }}
                whileInView={{
                  opacity: 1,
                  y: 0,
                  transition: {
                    duration: 1
                  }
                }}
              >
                <span className="subheading">NEW KART BUSINESS PVT.</span>
              </motion.div>

              <motion.div
                initial={{
                  opacity: 0,
                }}
                whileInView={{
                  opacity: 1,
                  transition: {
                    duration: 2
                  }
                }}
              >
                <h2 className="mb-1">Quality
                  & Choice.</h2>

              </motion.div>

              <motion.div
                initial={{
                  opacity: 0,
                  y: 100
                }}
                whileInView={{
                  opacity: 1,
                  y: 0,
                  transition: {
                    duration: 1
                  }
                }}

              >
                <p>Sourcing high grade steel from the most trusted mills in India, we also specialize in tailor made offerings based on your specifications.</p>
              </motion.div>
            </div>
          </div>
          <div className="projectlogo">

            {QualityCheck.map((data, i) => {
              return (
                <div key={i} className="col-md-3">
                  <div className="project2">
                    <img src={data.logo}>
                    </img>
                  </div>
                </div>
              )
            })}


          </div>
        </div>
      </section>

      <section className="ftco-intro">
        <div className="">
          <div className="row justify-content-center">
            <div className="col-md-12 text-center">
              <div className="img" style={{ backgroundImage: `url(${Background4})` }}>
                <div className="overlay"></div>
                <h2>Providing Personalized and High Quality Services</h2>
                <p>We can manage your dream building A small river named Duden flows by their place</p>
                <p className="mb-0"><Link to="/contact" className="btn btn-primary px-4 py-3">Contact Us</Link></p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default ourteam